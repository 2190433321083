import React, { useState, useRef, useEffect } from 'react';
import data from './data.json';
import config from './config';
import Spinner from '@components/Spinner';
import classNames from 'classnames';
import * as styles from '../Portfolio.module.scss';
import useWindowSize from '@hooks/useWindowSize';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = config.key;

const tabletSize = 990;
const mobileSize = 670;

export default React.memo(function Map({
  setCurrentLocation,
  map,
  setMap
}) {
  const mapContainer = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    const map = initMap(mapContainer.current, setCurrentLocation);
    map.on('load', () => setIsLoaded(true));
    setMap(map);

    return () => {
      map.isRemoved = true;
      map.remove();
    };
  }, [setMap, setCurrentLocation]);

  useEffect(() => {
    const mapEl = mapContainer.current;

    const wheelHandler = e => {
      if (map.isFlying || map.isResetting) {
        e.preventDefault();
        e.stopPropagation();

        return false;
      }
    };

    const keyHandler = e => {
      if (map.currentMarker && (e.key === 'Esc' || e.key === 'Escape')) {
        map.resetMap();
      }
    };

    if (map) {
      mapEl.addEventListener('wheel', wheelHandler, { passive: false });
      document.addEventListener('keydown', keyHandler);
    }

    return () => {
      mapEl.removeEventListener('wheel', wheelHandler);
      document.removeEventListener('keydown', keyHandler);
    };
  }, [map]);

  useEffect(() => {
    map?.setIsMobile(windowWidth <= mobileSize);
  }, [map, windowWidth]);

  return (
    <React.Fragment>
      <Spinner
        isLoading={!isLoaded}
        className={styles.spinner}
      />
      <div
        id="map"
        className={classNames('map-container', styles.map, {
          [styles.loading]: !isLoaded
        })}
        ref={mapContainer}
      />
    </React.Fragment>
  );
});

function initMap(container, setCurrentLocation) {
  // const { lng, lat, ...restPosition } = config.defaultPosition;
  const { lng, lat, ...restPosition } = getDefaultPosition();

  const map = new mapboxgl.Map({
    container: container,
    style: config.style,
    center: [lng, lat],
    minZoom: config.minZoom,
    maxZoom: config.maxZoom,
    localFontFamily: "'IBM Plex Sans', sans-serif",
    dragRotate: false,
    doubleClickZoom: false,
    touchPitch: false,
    antialias: true,
    ...restPosition
  });

  map.isMobileLayout = false;

  //////////////////////////
  // User interactions
  //////////////////////////

  function disableInteraction() {
    map.scrollZoom.disable();
    map.touchZoomRotate.disable();
    map.dragPan.disable();
    map.getCanvas().style.cursor = 'default';
  }

  function enableInteraction() {
    if (!map.currentMarker || !map.isMobileLayout) {
      if (!map.isMobileLayout) {
        map.scrollZoom.enable();
      }

      map.touchZoomRotate.enable();
      map.dragPan.enable();
      map.getCanvas().style.cursor = null;
    }
  }

  function setIsMobile(isMobile) {
    map.isMobileLayout = isMobile;

    if (isMobile) {
      if (map.currentMarker) {
        disableInteraction();
      } else {
        map.scrollZoom.disable();
      }
    } else {
      enableInteraction();
    }
  }

  map.setIsMobile = setIsMobile;

  //////////////////////////
  // Locations
  //////////////////////////

  // let currentLocation = null;

  function setLocation(marker) {
    if (!marker) {
      resetMap();
    } else {
      if (map.currentMarker === marker) {
        return;
      }

      map.currentMarker?.getElement().classList.remove(styles.current);
      map.currentMarker = marker;
      map.hasSetPitch = true;

      setCurrentLocation(marker);

      let { lng, lat } = marker.getLngLat();

      console.log(lat, lng);

      const {
        lngAdjust,
        latAdjust,
        mobileLngAdjust,
        mobileLatAdjust,
        ...restPosition
      } = config.location;

      if (map.isMobileLayout) {
        lng += mobileLngAdjust;
        lat += mobileLatAdjust;
      } else {
        lng += lngAdjust;
        lat += latAdjust;
      }

      fly(
        {
          // center: [lng + lngAdjust, lat + latAdjust],
          center: [lng, lat],
          ...restPosition
        },
        () => {
          map.currentMarker?.getElement().classList.add(styles.current);
        }
      );
    }
  }

  map.on('load', () => {
    setTimeout(() => {
      // createMarkers(map, setLocation);
      data.forEach((location, index) => {
        if (location.lng && location.lat) {
          createMarker(map, setLocation, location, index);
        }
      });
    }, 400);
  });

  //////////////////////////
  // Map Interactions
  //////////////////////////

  function fly(position, eventData = {}, callback) {
    disableInteraction();
    map.isFlying = true;

    // Allow callback to be passed as second argument
    // if no eventData is passed in
    if (!callback && typeof eventData === 'function') {
      callback = eventData;
    }

    map.once('flyend', e => {
      map.isFlying = false;

      setTimeout(() => {
        document.activeElement?.blur();

        if (!map.isFlying && !map.isResetting) {
          enableInteraction();
        }
      }, 20);

      if (callback) {
        callback(e);
      }
    });

    map.flyTo(position, { isProgrammatic: true, ...eventData });
  }

  function resetMap() {
    map.isResetting = true;
    map.currentMarker?.getElement().classList.remove(styles.current);

    setCurrentLocation(null);
    disableInteraction();

    // const { lat, lng, ...restPosition } = config.defaultPosition;
    const { lng, lat, ...restPosition } = getDefaultPosition();

    fly(
      {
        speed: config.resetZoomSpeed,
        center: [lng, lat],
        ...restPosition
      },
      { isResetting: true },
      () => {
        map.isResetting = false;
        map.currentMarker?.getElement().classList.remove(styles.current);
        map.currentMarker = null;
      }
    );
  }

  map.resetMap = resetMap;

  //////////////////////////
  // Events
  //////////////////////////

  // Fire custom events in order to tell
  // whether move was triggered by user or not
  map.on('movestart', ({ originalEvent }) => {
    if (originalEvent) {
      map.fire('usermovestart');
    } else {
      map.fire('flystart');
    }
  });

  map.on('move', ({ originalEvent }) => {
    if (originalEvent) {
      map.fire('usermove');
    } else {
      map.fire('fly');
    }
  });

  map.on('moveend', ({ originalEvent }) => {
    if (originalEvent) {
      map.fire('usermoveend');
    } else {
      map.fire('flyend');
    }
  });

  map.on('zoom', e => {
    if (
      !e.isProgrammatic &&
      e.originalEvent &&
      map.hasSetPitch &&
      map.getPitch() &&
      map.getZoom() <= config.resetZoomLevel
    ) {
      resetMap();
    }
  });

  // window.map = map;

  return map;
}

function createMarker(map, setLocation, location, index) {
  const { lng, lat } = location;
  const wrapper = document.createElement('div');
  wrapper.className = styles.markerWrapper;

  const el = document.createElement('div');
  el.className = styles.marker;

  // Add random delay between each marker animation
  const delay = 4 * (index > 20 ? index : 0) + 2300 * Math.random();
  el.style.animationDelay = `${delay}ms`;

  wrapper.appendChild(el);

  const marker = new mapboxgl.Marker({
    element: wrapper,
    anchor: 'bottom',
    draggable: false
  })
    .setLngLat([lng, lat])
    .addTo(map);

  marker.data = location;

  wrapper.addEventListener('click', () => {
    if (!map.isResetting && !map.isFlying) {
      setLocation(marker);
    }
  });
}

function getDefaultPosition() {
  const windowWidth = window.innerWidth;

  let {
    lng,
    lat,
    tabletLngAdjust,
    tabletLatAdjust,
    mobileLngAdjust,
    mobileLatAdjust,
    ...restPosition
  } = config.defaultPosition;

  if (windowWidth <= mobileSize) {
    lng += mobileLngAdjust;
    lat += mobileLatAdjust;
  } else if (windowWidth <= tabletSize) {
    lng += tabletLngAdjust;
    lat += tabletLatAdjust;
  }

  return { lng, lat, ...restPosition };
}
