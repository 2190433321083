// extracted by mini-css-extract-plugin
export const mapWrapper = "Portfolio-module--mapWrapper--3MIl5";
export const locationOpen = "Portfolio-module--locationOpen--1i7V8";
export const text = "Portfolio-module--text--3gTn2";
export const spinner = "Portfolio-module--spinner--vaG74";
export const map = "Portfolio-module--map--ij5GM";
export const loading = "Portfolio-module--loading--1cgN0";
export const markerWrapper = "Portfolio-module--markerWrapper--2iRPz";
export const marker = "Portfolio-module--marker--3yzK8";
export const markerSlideIn = "Portfolio-module--markerSlideIn--3i28i";
export const current = "Portfolio-module--current--2vfnL";
export const pulse = "Portfolio-module--pulse--ToQEI";
export const locationWrapper = "Portfolio-module--locationWrapper--3plJ4";
export const location = "Portfolio-module--location--375Da";
export const locationDetails = "Portfolio-module--locationDetails--3D1BE";
export const close = "Portfolio-module--close--1QgEM";
export const address = "Portfolio-module--address--3Q-8e";
export const features = "Portfolio-module--features--NUZKU";
export const active = "Portfolio-module--active--38DKI";
export const divider = "Portfolio-module--divider--1bT3C";