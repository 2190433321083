import React, { useState, useRef, useEffect } from 'react';
import Layout from '@layout';
import { H1 } from '@headline';
import LocationOverlay from './LocationOverlay';
import Map from './Map';
import * as styles from './Portfolio.module.scss';
import classNames from 'classnames';

export default function Portfolio() {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationIsOpen, setLocationIsOpen] = useState(!!currentLocation);
  const [map, setMap] = useState(null);

  // We store the current location data so that it remains
  // visible when the overlay box animates out
  const cachedLocation = useRef(currentLocation);
  const [visibleLocation, setVisibleLocation] = useState(null);

  useEffect(() => {
    if (currentLocation) {
      // cachedLocation.current = currentLocation;
      setVisibleLocation(currentLocation);
      setLocationIsOpen(true);
    } else {
      setLocationIsOpen(false);
    }
  }, [currentLocation]);

  return (
    <Layout>
      <section
        className={classNames(styles.mapWrapper, {
          [styles.locationOpen]: locationIsOpen
        })}
      >
        <div
          className={styles.text}
          aria-hidden={!locationIsOpen}
        >
          <H1 pretitle="Our portfolio">Building Southern Arizona</H1>
          <p>
            <strong>
              We've worked on hundreds of commercial, residential and
              environmental
            </strong>{' '}
            throughout the greater Tucson area.
          </p>
        </div>
        <LocationOverlay
          // location={cachedLocation.current}
          location={visibleLocation}
          locationIsOpen={locationIsOpen}
          map={map}
        />
        <Map
          setCurrentLocation={setCurrentLocation}
          map={map}
          setMap={setMap}
        />
      </section>
    </Layout>
  );
}
