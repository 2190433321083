export const config = {
  // Mapbox settings
  key:
    'pk.eyJ1IjoiY2hyaXNzdGlsZXMiLCJhIjoiY2ttb2hqYzV0MXlqZjJ2cndiMHdneWowZyJ9.DR3mcorUmR66rXljtT438w',
  id: 'chrisstiles/ckmpoai5l0bln17s33km6mtkf',
  style:
    'mapbox://styles/chrisstiles/ckmpoai5l0bln17s33km6mtkf?optimize=true',

  // General
  defaultPosition: {
    lng: -111.04538462986149,
    lat: 32.228278525104585,
    tabletLngAdjust: 0.03,
    tabletLatAdjust: 0,
    mobileLngAdjust: 0.11819534398085807,
    mobileLatAdjust: 0.04019278657507641,
    zoom: 10.66403986652783,
    pitch: 0
  },
  maxZoom: 19.75,
  minZoom: 5.86,
  resetZoomLevel: 17.412262686135833,
  resetZoomSpeed: 1.85,

  // Focused on specific location
  location: {
    lngAdjust: -0.0006709319287665494,
    latAdjust: 0,
    mobileLngAdjust: -0.00005822120645859741,
    mobileLatAdjust: -0.002038572369293945,
    pitch: 69,
    zoom: 18.224
  }
};

export default config;
