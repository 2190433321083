import React from 'react';
import { H2 } from '@headline';
import Close from '@components/CloseButton';
import Divider from '@components/Divider';
import classNames from 'classnames';
import * as styles from './Portfolio.module.scss';

export default React.memo(function LocationOverlay({
  location,
  map,
  locationIsOpen
}) {
  const data = location?.data;

  return (
    <div
      className={styles.locationWrapper}
      aria-hidden={!locationIsOpen}
    >
      {data && (
        <div className={classNames(styles.location, 'dot-bg')}>
          <div className={styles.locationDetails}>
            <Close
              className={styles.close}
              onClick={map?.resetMap}
            />
            <LocationInfo data={data} />
          </div>
          <FeaturesList data={data} />
        </div>
      )}
    </div>
  );
});

function LocationInfo({ data }) {
  const {
    name,
    addressBase,
    streetNumber,
    address,
    city,
    state,
    zip,
    description
  } = data;

  let addressLine1 = addressBase?.trim();
  let addressLine2 = null;

  if (streetNumber && address) {
    addressLine1 = `${streetNumber} ${address}`.trim();
  }

  if (city) {
    addressLine2 = [city, [state, zip].filter(i => i).join(' ')]
      .join(', ')
      .trim();
  }

  return (
    <React.Fragment>
      <H2 pretitle={description}>{name}</H2>
      <address className={styles.address}>
        {addressLine1 && <strong>{addressLine1}</strong>}
        {addressLine2 && <span>{addressLine2}</span>}
      </address>
      <Divider className={styles.divider} />
    </React.Fragment>
  );
}

function FeaturesList({ data }) {
  return (
    <ul className={styles.features}>
      {Object.keys(featureListItems).map((key, index) => (
        <li
          key={index}
          className={classNames({ [styles.active]: data[key] })}
        >
          {featureListItems[key]}
        </li>
      ))}
    </ul>
  );
}

const featureListItems = {
  nppo: 'Native Plant Preservation Ordinance (NPPO)',
  landscapeIrrigation: 'Landscape & Irrigation Plans',
  rhmp: 'Riparian Habitat Mitigation Plan (RHMP)',
  waterHarvesting: 'Water Harvesting Plans',
  rezoning: 'Rezoning Support',
  endangeredSpecies: 'Endangered Species Search'
};
